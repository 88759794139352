<template>
  <FullScreenDialog
    v-model="localDialog"
    title="Ricarica Tessera"
    without-padding
    @input="handleFullScreenDialogChange"
    :can-close="canCloseDialog"
  >
    <template v-slot:content>
      <template v-if="cashDeskOpened">
        <v-stepper v-model="currentStep" height="100%">
          <v-stepper-header>
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :key="step.name"
                :complete="currentStep > index + 1"
                :step="index + 1"
                :editable="currentStep > index + 1 && canChangeStep"
                :disabled="!canChangeStep"
                @click="handleStepClicked(index + 1)"
                edit-icon="mdi-check"
                v-ripple="false"
              >
                {{ step.title }}
                <div class="text-caption">{{ step.subtitle }}</div>
              </v-stepper-step>
              <v-divider
                :key="step.name + 'divider'"
                v-if="index != steps.length - 1"
              ></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <template v-for="(step, index) in steps">
              <v-stepper-content
                :step="index + 1"
                :key="step.name"
                v-if="step.name == 'invoiceOrReceipt'"
              >
                <div
                  style="height: 70vh; width: 100%"
                  class="d-flex justify-center align-center flex-wrap"
                >
                  <v-card
                    class="text-h6 d-flex align-center justify-center"
                    height="200"
                    width="200"
                    @click="handleInvoiceClick()"
                  >
                    Fattura
                  </v-card>
                  <v-card
                    class="text-h6 d-flex align-center justify-center ml-2"
                    height="200"
                    width="200"
                    @click="handleReceiptClick()"
                  >
                    Scontrino
                  </v-card>
                  <v-card
                    v-if="!step.name == 'invoiceOrReceipt'"
                    class="text-h6 d-flex align-center justify-center ml-2 text-center"
                    height="200"
                    width="200"
                    @click="handlePayLaterClick()"
                  >
                    Paga Successivamente
                  </v-card>
                </div>
              </v-stepper-content>
              <v-stepper-content
                :step="index + 1"
                :key="step.name"
                v-else-if="step.name == 'summary'"
              >
                <div
                  style="max-height: calc(100vh - 64px - 72px - 52px - 40px); height: 800px; width: 100%;"
                >
                  <PaymentSummary
                    ref="paymentSummary"
                    :total="totalValue"
                    :local-customer-card="localCustomerCard"
                    :provided-entities="providedServices"
                    :item-price="(billService) => billService.service.price"
                    :item-name="(billService) => billService.service.name"
                    :hide-paid-amounts="
                      invoiceOrReceipt == 'invoice' ||
                        invoiceOrReceipt == 'payLater'
                    "
                    :paid-with-cash.sync="paidWithCash"
                    :paid-with-card.sync="paidWithCard"
                    :paid-with-tickets.sync="paidWithTickets"
                    :paid-with-check.sync="paidWithCheck"
                    :not-paid.sync="notPaid"
                    :opened-cash-desk="openedCashDesk"
                    :customer="customer"
                    @confirm="goToStep(3)"
                    @go-back="goBackToStep(currentStep - 1)"
                    @dblclick-row="handleDoubleClickRow"
                    :lottery-code.sync="lotteryCode"
                    :use-lottery-code.sync="useLotteryCode"
                  ></PaymentSummary>
                </div>
              </v-stepper-content>
              <v-stepper-content
                :step="index + 1"
                :key="step.name"
                v-else-if="step.name == 'payment'"
              >
                <div
                  style="height: 70vh;"
                  class="d-flex flex-column justify-center align-center flex-wrap"
                >
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="invoicePrinted"
                    ></AnimatedCheck>
                    <div
                      class="text-h6 text-center"
                      style="width: 500px; max-width: 90vw"
                    >
                      {{ invoicePrintedMessage }}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="transactionClosed"
                    ></AnimatedCheck>
                    <div
                      class="text-h6 text-center"
                      style="width: 500px; max-width: 90vw"
                    >
                      {{ transactionClosedMessage }}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="dataRegistered"
                    ></AnimatedCheck>
                    <div
                      class="text-h6 text-center"
                      style="width: 500px; max-width: 90vw"
                    >
                      {{ dataRegisteredMessage }}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center mt-5">
                    <v-btn
                      icon
                      @click="closeDialog"
                      :disabled="canCloseDialog"
                    ></v-btn>
                  </div>
                </div>
              </v-stepper-content>
              <v-snackbar v-model="messageError" :key="step.name + 'snackbar'" timeout="-1">
                {{ messageErrorText }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="messageError = false"
                  >
                    Chiudi
                  </v-btn>
                </template>
              </v-snackbar>
            </template>
          </v-stepper-items>
        </v-stepper>
      </template>
      <template v-else>
        Non puoi procedere con la cassa chiusa
      </template>
    </template>
  </FullScreenDialog>
</template>

<script>
import Printer from "@/services/devices/rtPrinter";
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";
import StandardDialog from "@/components/common/StandardDialog";
import FullScreenDialog from "@/components/common/FullScreenDialog";
import customerCardService from "@/services/customers/customerCards.service.js";
import OperatorAvatar from "@/components/common/OperatorAvatar.vue";
import smsService from "@/services/sms/sms.service.js";
import NumericKeyboardInput from "@/components/common/NumericKeyboardInput.vue";
import AnimatedCheck from "@/components/common/AnimatedCheck.vue";
import PaymentSummary from "@/components/homepage/sections/PaymentSummary.vue";
import settingService from "@/services/settings/settings.service.js";

export default {
  name: "CustomerCardLoadDialog",
  components: {
    StandardDialog,
    FullScreenDialog,
    OperatorAvatar,
    NumericKeyboardInput,
    AnimatedCheck,
    PaymentSummary,
  },
  data: function() {
    return {
      localCustomerCard: undefined,
      localDialog: this.dialog,
      currentStep: 1,
      messageError: false,
      messageErrorText: undefined,
      invoiceOrReceipt: undefined,
      total: undefined,
      totalValue: this.loadValue,
      receiptScrollY: 0,
      openedCashDesk: undefined,
      canScrollReceipt: false,
      paidWithCash: 0,
      paidWithCard: 0,
      paidWithTickets: 0,
      paidWithCheck: 0,
      notPaid: 0,
      transactionClosed: false,
      dataRegistered: false,
      invoicePrinted: false,
      canCloseDialog: true,
      canChangeStep: true,
      ghost: false,
      whatsappNumber: undefined,
      lotteryCode: undefined,
      useLotteryCode: undefined,
      taxForSaloon: undefined
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    goBack: {
      type: Boolean,
      default: false,
    },
    goBackTo: {},
    customerCardId: {},
    customerCard: {},
    customer: {},
    loadValue: {
      type: Number,
      default: 0,
    },
  },
  mounted: function() {
    this.loadSettings();

    if (!!this.customerCard) {
      this.localCustomerCard = { ...this.customerCard };
    } else if (!!this.customerCardId) {
      customerCardService
        .get({ id: this.customerCardId })
        .then((customerCard) => {
          this.localCustomerCard = customerCard;
        });
    }

    this.getOpenedCashDesk();
  },
  methods: {
    loadSettings: async function() {
      settingService.list("company").then((list) => {
        let settings = [];
        for (const [key, value] of Object.entries(list)) {
          settings.push({
            key: key,
            value: value.value,
          });
        }
        this.settings = settings;
      });
    },
    closeDialog() {
      this.$emit("update:dialog", false);
      let goBack;
      if (!!this.goBack) {
        goBack = () => {
          this.$router.go(-1);
        };
      } else if (!!this.goBackTo) {
        let goBackTo = { ...this.goBackTo };
        goBack = () => {
          this.$router.push(this.goBackTo);
        };
      }
      setTimeout(goBack.bind(this), 200);
    },
    getOpenedCashDesk() {
      cashDeskService.getOpened().then((openedCashDesk) => {
        this.openedCashDesk = openedCashDesk;
      });
    },
    handleFullScreenDialogChange(newVal) {
      if (!newVal) {
        this.closeDialog();
      }
    },
    startFromScratch() {
      this.invoiceOrReceipt = undefined;
      this.currentStep = 1;
    },
    setInvoiceOrReceipt(type) {
      this.invoiceOrReceipt = type;
    },
    goToStep(number) {
      const step = this.steps[number - 1];
      if (step.name == "summary") {
        setTimeout(() => {
          this.$refs.paymentSummary[0].calculateScrollReceipt();
        }, 200);
      } else if (step.name == "payment") {
        setTimeout(() => {
          this.pay();
        }, 1000);
      }
      this.currentStep = number;
    },
    goBackToStep(number) {
      const step = this.steps[number - 1];
      if (step.name == "invoiceOrReceipt") {
        this.invoiceOrReceipt = undefined;
      }
      this.goToStep(number);
    },
    handleStepClicked(number) {
      if (this.currentStep == number || !this.canChangeStep) {
        return;
      } else if (this.currentStep < number) {
        this.$delegates.snackbar("Confermare prima di avanzare");
      } else {
        this.goBackToStep(number);
      }
    },
    handleInvoiceClick() {
      this.setInvoiceOrReceipt("invoice");
      this.$nextTick(() => {
        this.goToStep(2);
      });
    },
    handleReceiptClick() {
      this.setInvoiceOrReceipt("receipt");
      this.$nextTick(() => {
        this.goToStep(2);
      });
    },
    handlePayLaterClick() {
      this.setInvoiceOrReceipt("payLater");
      this.$nextTick(() => {
        this.goToStep(2);
      });
    },
    handleDoubleClickRow(item) {
      if (!!this.localCustomerCard && this.localCustomerCard.lastLoadPaymentTransactionId != undefined){
        this.ghost = !this.ghost;
        this.paidWithCash = 0
        this.paidWithCard = 0
        this.paidWithTickets = 0
        this.paidWithCheck = 0
        this.notPaid = 0
      }
    },
    async pay() {
      try {
        this.transactionClosed = false;
        this.dataRegistered = false;
        this.invoicePrinted = false;
        this.canCloseDialog = false;
        this.canChangeStep = false;

        if(Number(this.paidWithCash) + (Number(this.paidWithCard) || 0) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue)) {
          this.paidWithCash = Math.max(Number(this.totalValue) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
        }

        if(Number(this.paidWithCard) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0)) {
          this.paidWithCard = Math.max(Number(this.totalValue) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
        }

        if(Number(this.paidWithTickets) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0)) {
          this.paidWithTickets = Math.max(Number(this.totalValue) - (Number(this.paidWithCheck) || 0), 0)
        }

        if(Number(this.paidWithCheck) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0)) {
          this.paidWithCheck = Number(this.totalValue)
        }

        let paymentTypes = [];
        if (!!this.paidWithCash) {
          paymentTypes.push({
            amount: this.paidWithCash,
            type: "cash",
          });
        }

        if (!!this.paidWithCard) {
          paymentTypes.push({
            amount: this.paidWithCard,
            type: "card",
          });
        }

        if (!!this.notPaid) {
          paymentTypes.push({
            amount: this.notPaid,
            type: "credit",
          });
        }

        if (!!this.paidWithTickets) {
          paymentTypes.push({
            amount: this.paidWithTickets,
            type: "ticket",
          });
        }

        if (!!this.paidWithCheck) {
          paymentTypes.push({
            amount: this.paidWithCheck,
            type: "cheque",
          });
        }

        try {
          let printerStatus = await Printer.getStatus()
        } catch (exception) {
          console.log(exception)

          this.$delegates.snackbar('Errore durante la connessione con la stampante')
          this.canCloseDialog = true
          return
        }

        let rtResponse;
        try {
          if (this.invoiceOrReceipt == "receipt" && !this.ghost) {
            rtResponse = await Printer.printFiscalReceipt({
              paymentTypes: paymentTypes,
              lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
              items: this.providedServices.map((ps) => ({
                name: ps.service.name.replace("&", "e"),
                price: ps.service.price,
                quantity: ps.quantity,
                tax: this.taxForSaloon === undefined || this.taxForSaloon === null ? '22%' : this.taxForSaloon + '%'
              })),
            }, "ricarica tessera receipt not Ghost");
          } else if (this.invoiceOrReceipt == "invoice" && !this.ghost) {
            await Printer.printSummary(
              {
                amount: this.loadValue,
                invoiceData: this.invoiceData,
                items: this.providedServices.map((ps) => ({
                  name: ps.service.name.replace("&", "e"),
                  price: ps.service.price,
                  quantity: ps.quantity,
                })),
              },
              true,
              "ricarica tessera invoice not Ghost"
            );
          } else if (this.invoiceOrReceipt == "payLater" && !this.ghost) {
            rtResponse = await Printer.printFiscalReceipt({
              paymentTypes: [
                {
                  amount: this.loadValue,
                  type: "credit",
                },
              ],
              lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
              items: this.providedServices.map((ps) => ({
                name: ps.service.name.replace("&", "e"),
                price: ps.service.price,
                quantity: ps.quantity,
                tax: this.taxForSaloon === undefined || this.taxForSaloon === null ? '22%' : this.taxForSaloon + '%'
              })),
            }, "ricarica tessera payLater not Ghost");
          }
        } catch (exception) {
          console.log(exception)

          if (String(exception).includes("URL") || String(exception).includes("protocol")) {
            this.$delegates.snackbar('Stampante non collegata / Indirizzo stampante errato', 0)
            this.canCloseDialog = true
            return
          }

          // if something goes wrong manually set the success to false
          rtResponse = {
            attributes: {
              success: 'false'
            }
          }
        }

        if(!!rtResponse && !!rtResponse.attributes && rtResponse.attributes.success == 'false') {
          this.$delegates.snackbar('Errore durante la stampa dello scontrino')
          this.canCloseDialog = true
          return 
        }
        
        this.invoicePrinted = true;
        this.canCloseDialog = true;
        // this.canChangeStep = true
        let documentNumberFixed;

        if (!!rtResponse && !!rtResponse.addInfo) {
          rtResponse.addInfo.zRepNumber = "" + rtResponse.addInfo.zRepNumber;
          rtResponse.addInfo.fiscalReceiptNumber =
            "" + rtResponse.addInfo.fiscalReceiptNumber;
          while (rtResponse.addInfo.zRepNumber.length < 4) {
            rtResponse.addInfo.zRepNumber = "0" + rtResponse.addInfo.zRepNumber;
          }
          while (rtResponse.addInfo.fiscalReceiptNumber.length < 4) {
            rtResponse.addInfo.fiscalReceiptNumber =
              "0" + rtResponse.addInfo.fiscalReceiptNumber;
          }
          documentNumberFixed =
            rtResponse.addInfo.zRepNumber +
            "-" +
            rtResponse.addInfo.fiscalReceiptNumber;
        }

        if (this.invoiceOrReceipt == "payLater") {
          await customerCardService.transact(
            this.localCustomerCard,
            this.openedCashDesk,
            this.loadValue,
            {
              customer: {
                id: this.customer.id,
                gender: this.customer.gender,
                lastname: this.customer.lastname,
                firstname: this.customer.firstname
              },
              notPaid: this.loadValue,
              documentType: "payLater",
              documentNumber: !!documentNumberFixed
                ? documentNumberFixed
                : undefined,
            }
          );
        } else {
          await customerCardService.transact(
            this.localCustomerCard,
            this.openedCashDesk,
            this.loadValue,
            {
              customer: {
                id: this.customer.id,
                gender: this.customer.gender,
                lastname: this.customer.lastname,
                firstname: this.customer.firstname
              },
              cashPaid: this.paidWithCash,
              cardPaid: this.paidWithCard,
              checkPaid: this.paidWithCheck,
              otherPaid: this.paidWithTickets,
              notPaid: this.notPaid,
              ghostPaid: this.ghost ? this.loadValue : 0,
              documentType: this.invoiceOrReceipt,
              documentNumber: !!documentNumberFixed
                ? documentNumberFixed
                : undefined,
            }
          );
        }

        this.transactionClosed = true;
        this.dataRegistered = true;
        if (!!rtResponse && rtResponse.attributes.success == "true") {
          let customerCardMessage = `Gentile ${this.customer.firstname} ${this.customer.lastname} la sua tessera è stata ricaricata di ${this.loadValue} €`;
          let phoneNumbers = [];
          if (!!this.customer.prefixCellphone && !!this.customer.cellphone) {
            phoneNumbers.push(
              this.customer.prefixCellphone + this.customer.cellphone
            );
            if (this.customer.sendByWhatsapp == true && !!this.whatsappNumber) {
              phoneNumbers[0] = phoneNumbers[0].replace("+", "");
              smsService
                .sendWhatsappMessage(
                  customerCardMessage,
                  this.whatsappNumber,
                  phoneNumbers[0]
                )
                .then((response) => {
                  this.$delegates.snackbar(
                    "Messaggio Whatsapp inviato con successo"
                  );
                });
            } else {
              smsService
                .sendSmsEsendex(customerCardMessage, phoneNumbers, this.customer.id)
                .then(() => {
                  this.$delegates.snackbar("Sms inviato con successo");
                });
            }
          }
        }
        setTimeout(this.closeDialog, 2000);
      } catch (err) {
        if (String(err).includes("URL") || String(err).includes("protocol")) {
          this.messageErrorText =
            "Stampante non collegata / Indirizzo stampante errato";
          this.messageError = true;
        } else {
          this.messageErrorText = err;
          this.messageError = true;
        }
      }
    },
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal;
      if (newVal) {
        this.startFromScratch();
      }
    },
    ghost(newVal) {
      if (newVal) {
        this.totalValue = 0;
      } else {
        this.totalValue = this.loadValue;
      }
    },
  },
  computed: {
    steps() {
      let steps = [];

      if (!this.invoiceOrReceipt) {
        steps.push({
          title: "Scontrino o Fattura",
          name: "invoiceOrReceipt",
        });

        steps.push({
          title: "Dati Fatturazione",
          name: "invoiceData",
        });
      } else if (this.invoiceOrReceipt == "invoice") {
        steps.push({
          title: "Fattura",
          name: "invoiceOrReceipt",
        });

        steps.push({
          title: "Dati Fatturazione",
          name: "invoiceData",
        });
      } else if (
        this.invoiceOrReceipt == "receipt" ||
        this.invoiceOrReceipt == "payLater"
      ) {
        steps.push({
          title: "Scontrino",
          name: "invoiceOrReceipt",
        });
      }

      steps.push({
        title: "Riepilogo",
        name: "summary",
      });

      steps.push({
        title: "Pagamento",
        name: "payment",
      });

      return steps;
    },
    providedServices() {
      return [
        {
          id: 1,
          ghost: this.ghost,
          ghostQuantity: this.ghost ? 1 : 0,
          service: {
            name: "Ricarica Tessera",
            price: this.loadValue,
          },
          quantity: 1,
        },
      ];
    },
    cashDeskOpened() {
      return !!this.openedCashDesk;
    },
    transactionClosedMessage() {
      return this.transactionClosed
        ? "Transazione eseguita correttamente"
        : "Transazione in corso ...";
    },
    dataRegisteredMessage() {
      return this.dataRegistered
        ? "Dati registrati correttamente"
        : "Registrazione dei dati ...";
    },
    invoicePrintedMessage() {
      return this.invoicePrinted
        ? "Scontrino stampato correttamente"
        : "Stampa dello scontrino ...";
    },
  },
  settings: {
    whatsappNumber: {
      bind: "whatsappNumber",
    },
    taxForSaloon: {
      bind: 'taxForSaloon'
    }
  },
};
</script>

<style></style>
